import * as React from 'react'
import { Link } from 'gatsby'
import Picture from '../images/home-school.jpg'

const HomeNew = () => {
  return (
    <React.Fragment>
        <section id="home-new">
            <div className="container">
                <div className="left">
                    <h2 className="title">
                        Inscrivez-vous dès maintenant pour le prochain cours !
                    </h2>
                    <p className="description">
                        Chaque dimanche venez partager un moment d'apprentissage et de bien-être pour votre chien et vous.
                    </p>
                    <Link to={'/inscription-aux-cours'} className="button primary">
                        Inscrivez-vous
                    </Link>
                </div>
                <div className="right">
                    <div className="picture-border">
                        <div className="picture-container">
                            <img src={Picture} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
  )
}

export default HomeNew
