import * as React from 'react'
import LogoPazapat from '../images/logo-pazapat.png'
import LogoFeelDoog from '../images/logo-feeldoog.png'
import LogoVillasAgenceImmobiliere from '../images/logo-villas-agence-immobiliere.png'
import LogoRiga from '../images/logo-riga.png'
import LogoBrasserieHeritage from '../images/logo-brasserie-heritage.png'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Partners = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        centerMode: true,
        centerPadding: '45px',
        variableWidth: false,
        responsive: [
            {
                breakpoint: 1550,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
            },
            {
              breakpoint: 1250,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 750,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    }

    return (
        <>
            <section id="partners">
                <h2 className="title">
                    Nos partenaires
                </h2>
                <div className="container">
                    <Slider className="carousel" {...settings}>
                        <a href="https://www.feel-doog.be" target="_blank" className="medium-logo" rel="noopener">
                            <img src={LogoFeelDoog} alt="Feel-Doog, Alexia Derycker - Comportementaliste et éducatrice canine" />
                            <span className="title">Feel Doog</span>
                            <strong>Alexia Derycker<br />
                            Comportementaliste &amp; éducatrice canine</strong>
                            hello@feel-doog.be<br />
                            +32 (0)472 992 337
                        </a>
                        <a href="https://www.pazapat.be" target="_blank" className="medium-logo" rel="noopener">
                            <img src={LogoPazapat} alt="SPRL PAZAPAT ‘" />
                            <span className="title">PAZAPAT‘</span>
                            <strong>Alimentation, accessoires et toilettage</strong>
                            94 A, Grand-Rue<br />
                            6730 Tintigny<br />
                        </a>
                        <a href="https://www.villas.immo" target="_blank" className="small-logo" rel="noopener">
                            <img src={LogoVillasAgenceImmobiliere} alt="VILLAS agence immobilière" />
                            <span className="title">VILLAS agence immobilière</span>
                            <strong>Agence immobilière familiale et de proximité</strong>
                            info@villas.immo<br />
                            +32 (0)472 65 07 78
                        </a>
                        <a href="https://www.riga.fr" target="_blank" className="small-logo" rel="noopener">
                            <img src={LogoRiga} alt="RIGA - Le choix pour votre animal" />
                            <span className="title">RIGA</span>
                            <strong>Le choix pour votre animal</strong>
                            www.riga.fr<br />
                        </a>
                        <a href="https://brasserie-heritage.be/" target="_blank" className="small-logo" rel="noopener">
                            <img src={LogoBrasserieHeritage} alt="Brasserie Héritage - Micro-brasserie artisanale indépendante" />
                            <span className="title">Brasserie Héritage</span>
                            <strong>Micro-brasserie artisanale indépendante</strong>
                            www.brasserie-heritage.be<br />
                        </a>
                    </Slider>
                </div>
            </section>
        </>
    )
}

export default Partners